import { CircularProgress, createStyles, Divider, Grid, Typography, WithStyles } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import moment from 'moment';
import 'moment/locale/pl';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { FacebookIcon, FacebookShareButton, TwitterIcon, TwitterShareButton } from 'react-share';
import { API } from '../../api';
import { Article } from '../../api/response';
import GetOnStores from '../../components/GetOnStores';
import { routesDetails } from '../../routes/routesDetails';

// #region UI
const dateFormat: string = 'DD MMM YYYY';

const styles = () =>
    createStyles({
        date: {
            color: '#A1A5B1',
            fontSize: '15pt',
        },
        divider: {
            margin: '24px 0',
        },
        photo: {
            height: 400,
            objectFit: 'cover',
            width: '100%',
        },
        progress: {
            margin: 64,
        },
        title: {
            color: '#171D33',
            fontSize: '22pt',
            fontWeight: 600,
        },
    });
// #endregion

// #region Props & State
interface RouteParams {
    articleId: string;
    slug: string;
}
interface ComponentProps extends RouteComponentProps<RouteParams>, WithStyles<typeof styles> {}

interface ComponentState {
    article: Article | null;
    loading: boolean;
}

const initialState: ComponentState = {
    article: null,
    loading: false,
};
// #endregion

class ArticlePage extends React.Component<ComponentProps, ComponentState> {
    constructor(props: ComponentProps) {
        super(props);

        this.state = initialState;
        moment.locale('pl');
    }

    // #region Lifecycle
    public componentDidMount(): void {
        this.load();
    }

    public render(): React.ReactNode {
        const { classes } = this.props;
        const { article, loading } = this.state;

        if (loading) {
            return (
                <Grid container={true} justify="center">
                    <CircularProgress className={classes.progress} />
                </Grid>
            );
        }

        if (article === null) {
            return null;
        }

        return (
            <Grid container={true} justify="center" direction="row">
                {article.photoFilename !== null && (
                    <Grid item={true} xs={12}>
                        <img
                            className={classes.photo}
                            alt={article.title}
                            src={API.articles.photo(article.photoFilename)}
                        />
                    </Grid>
                )}

                <Grid item={true} xs={7}>
                    {article.publicationDate !== null && (
                        <Typography className={classes.date}>
                            {moment(article.publicationDate).format(dateFormat)}
                        </Typography>
                    )}

                    <Typography className={classes.title}>{article.title}</Typography>
                </Grid>

                <Grid item={true} xs={7}>
                    <Grid container={true} spacing={1}>
                        <Grid item={true}>
                            <FacebookShareButton url={window.location.href}>
                                <FacebookIcon size={32} round={true} />
                            </FacebookShareButton>
                        </Grid>
                        <Grid item={true}>
                            <TwitterShareButton url={window.location.href}>
                                <TwitterIcon size={32} round={true} />
                            </TwitterShareButton>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item={true} xs={7} dangerouslySetInnerHTML={{ __html: article.content }} />

                <Grid item={true} xs={12}>
                    <Divider variant="inset" className={classes.divider} />
                </Grid>

                <Grid item={true} xs={7}>
                    <GetOnStores />
                </Grid>
            </Grid>
        );
    }
    // #endregion

    // #region Private
    private load = async (): Promise<void> => {
        const { history } = this.props;
        const { articleId } = this.props.match.params;
        const id: number = parseInt(articleId, 10);

        if (id === 0) {
            history.replace(routesDetails.error.notFound.path);
        } else {
            try {
                this.setState({ loading: true });
                const article = await API.articles.publishedDetails(id);
                this.setState({ article, loading: false });
            } catch (error) {
                history.replace(routesDetails.error.notFound.path);
            }
        }
    };
    // #endregion
}

export default withStyles(styles)(ArticlePage);
