import _ from 'lodash';
import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { routesDetails } from './routesDetails';

export default class Routes extends React.Component {
    public render() {
        return (
            <BrowserRouter>
                <Switch>
                    {_.values(routesDetails.pages).map((route, index) => (
                        <Route
                            key={index}
                            exact={true}
                            strict={true}
                            sensitive={true}
                            path={route.path}
                            component={route.component}
                        />
                    ))}
                    <Route component={routesDetails.error.notFound.component} />
                </Switch>
            </BrowserRouter>
        );
    }
}
