import { createMuiTheme, CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import React from 'react';
import Routes from '../../routes/Routes';

const theme = createMuiTheme({});

class App extends React.Component {
    public render(): React.ReactNode {
        return (
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Routes />
            </ThemeProvider>
        );
    }
}

export default App;
