import { CircularProgress, createStyles, Divider, Grid, Typography, WithStyles, Box } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { API } from '../../api';
import { Product } from '../../api/response';
import logo from '../../assets/logo.png';
import unknownIngredient from '../../assets/unknown_ingredient.svg';
import GetOnStores from '../../components/GetOnStores';
import { routesDetails } from '../../routes/routesDetails';

// #region UI
const strings = {
    text: {
        ingredients: 'Skład',
        label: 'Skład z etykiety',
        noIngredients: 'Produkt jednoskładnikowy lub brak informacji o składzie',
    },
};

const styles = () =>
    createStyles({
        divider: {
            margin: '24px 0',
        },
        ingredientDescription: {
            color: '#A1A5B1',
            fontSize: '12px',
            fontWeight: 500,
        },
        ingredientName: {
            color: '#171D33',
            fontSize: '14px',
            fontWeight: 'bold',
        },
        ingredientPhoto: {
            height: 48,
            marginRight: 8,
            objectFit: 'cover',
            width: 48,
        },
        ingredients: {
            color: '#171D33',
            fontSize: '20px',
            fontWeight: 500,
            padding: '8px 0',
        },
        logo: {
            maxHeight: 200,
            padding: 16,
        },
        name: {
            color: '#171D33',
            fontSize: '17px',
            fontWeight: 'bold',
        },
        packSize: {
            color: '#A1A5B1',
            fontSize: '15px',
            fontWeight: 500,
        },
        photo: {
            maxHeight: 300,
            padding: 16,
        },
        progress: {
            margin: 64,
        },
        unknownIngredientPhoto: {
            background: '#A1A4B2',
            borderRadius: '8px',
            color: '#fff',
            height: 48,
            marginRight: 8,
            objectFit: 'cover',
            padding: 6,
            width: 48,
        },
    });
// #endregion

// #region Props & State
interface RouteParams {
    productId: string;
    gtin: string;
}
interface ComponentProps extends RouteComponentProps<RouteParams>, WithStyles<typeof styles> {}

interface ComponentState {
    product: Product | null;
    loading: boolean;
}

const initialState: ComponentState = {
    loading: false,
    product: null,
};
// #endregion

class ProductPage extends React.Component<ComponentProps, ComponentState> {
    constructor(props: ComponentProps) {
        super(props);

        this.state = initialState;
    }

    // #region Lifecycle
    public componentDidMount(): void {
        this.load();
    }

    public render(): React.ReactNode {
        const { classes } = this.props;
        const { product, loading } = this.state;

        if (loading) {
            return (
                <Grid container={true} justify="center">
                    <CircularProgress className={classes.progress} />
                </Grid>
            );
        }

        if (product === null) {
            return null;
        }

        return (
            <Grid container={true} justify="center" direction="column" alignContent="center">
                {product.photoFilename !== null && (
                    <Grid item={true} xs={11} md={9} lg={8} style={{ textAlign: 'center' }}>
                        <img
                            className={classes.photo}
                            alt={product.name}
                            src={API.products.photo(product.id, product.photoFilename)}
                        />
                    </Grid>
                )}

                {product.photoFilename === null && (
                    <Grid item={true} xs={11} md={9} lg={8} style={{ textAlign: 'center' }}>
                        <img className={classes.logo} alt={product.name} src={logo} />
                    </Grid>
                )}

                <Grid item={true} xs={11} md={9} lg={8} style={{ textAlign: 'center' }}>
                    <Typography className={classes.name}>{product.name}</Typography>
                </Grid>

                {product.packSize !== null && (
                    <Grid item={true} xs={11} md={9} lg={8} style={{ textAlign: 'center' }}>
                        <Typography className={classes.packSize}>{product.packSize}</Typography>
                    </Grid>
                )}

                <Grid item={true} xs={11} md={9} lg={8}>
                    <Typography className={classes.ingredients}>
                        {product.ingredients.length > 0 ? strings.text.ingredients : strings.text.noIngredients}
                    </Typography>
                </Grid>

                {product.ingredients.map((productIngredient, index) => (
                    <Grid item={true} xs={11} md={9} lg={8} key={index}>
                        <Box display="flex" flexDirection="row" alignItems="center">
                            <Box style={{ height: 48 }} flexBasis={48}>
                                {productIngredient.ingredient !== null && (
                                    <img
                                        alt={productIngredient.ingredient.name}
                                        className={classes.ingredientPhoto}
                                        src={API.products.ingredientPhoto(
                                            productIngredient.ingredient.id,
                                            productIngredient.ingredient.photoFilename
                                        )}
                                    />
                                )}
                                {productIngredient.ingredient === null && (
                                    <img
                                        className={classes.unknownIngredientPhoto}
                                        alt={productIngredient.normalizedIngredient.displayName}
                                        src={unknownIngredient}
                                    />
                                )}
                            </Box>
                            <Box
                                flexGrow="1"
                                style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}
                            >
                                <Typography className={classes.ingredientName} noWrap={true}>
                                    {productIngredient.ingredient !== null
                                        ? productIngredient.ingredient.name
                                        : productIngredient.normalizedIngredient.displayName}
                                </Typography>

                                {productIngredient.ingredient !== null && (
                                    <Typography className={classes.ingredientDescription} noWrap={true}>
                                        {productIngredient.ingredient.details}
                                    </Typography>
                                )}
                            </Box>
                        </Box>

                        <Grid item={true}>&nbsp;</Grid>
                    </Grid>
                ))}

                {product.label.length > 0 && (
                    <Grid item={true} xs={11} md={9} lg={8}>
                        <Typography className={classes.ingredients} style={{ paddingBottom: 0 }}>
                            {strings.text.label}
                        </Typography>
                    </Grid>
                )}

                {product.label.length > 0 && (
                    <Grid item={true} xs={11} md={9} lg={8}>
                        <Typography className={classes.ingredientDescription}>{product.label.join(', ')}</Typography>
                    </Grid>
                )}

                <Grid item={true} xs={12}>
                    <Divider variant="inset" className={classes.divider} />
                </Grid>

                <Grid item={true} xs={11} md={9} lg={8}>
                    <GetOnStores />
                </Grid>
            </Grid>
        );
    }
    // #endregion

    // #region Private
    private load = async (): Promise<void> => {
        const { history } = this.props;
        const { productId, gtin } = this.props.match.params;
        const id: number = parseInt(productId, 10);

        if (id === 0) {
            history.replace(routesDetails.error.notFound.path);
        } else {
            try {
                this.setState({ loading: true });
                const product = await API.products.details(id, gtin);
                this.setState({ product, loading: false });
            } catch (error) {
                history.replace(routesDetails.error.notFound.path);
            }
        }
    };
    // #endregion
}

export default withStyles(styles)(ProductPage);
