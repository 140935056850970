import client from './client';
import * as Model from './response';

const articlesPublishedDetails = async (articleId: number): Promise<Model.Article> => {
    const result = await client.get<Model.Article>(`articles/published/${articleId}`);
    return result.data;
};

const articlesPhoto = (photoFilename: string): string => {
    return `${process.env.REACT_APP_API_URL}articles/photos/original/${photoFilename}`;
};

const productsDetails = async (productId: number, gtin: string): Promise<Model.Product> => {
    const result = await client.get<Model.Product>(`products/${productId}/${gtin}`);
    return result.data;
};

const productsPhoto = (productId: number, photoFilename: string): string => {
    return `${process.env.REACT_APP_API_URL}products/${productId}/photos/420/${photoFilename}`;
};

const ingredientPhotoUrl = (ingredientId: number, photoFilename: string): string => {
    return `${process.env.REACT_APP_API_URL}ingredients/${ingredientId}/photos/${photoFilename}.png`;
};

export const API = {
    articles: {
        photo: articlesPhoto,
        publishedDetails: articlesPublishedDetails,
    },
    products: {
        details: productsDetails,
        ingredientPhoto: ingredientPhotoUrl,
        photo: productsPhoto,
    },
};
