import React from 'react';
import { isLocalHost } from '../utils/isLocalHost';

class HttpsRedirect extends React.Component {
    public render(): React.ReactNode {
        if (
            typeof window !== 'undefined' &&
            window.location &&
            window.location.protocol === 'http:' &&
            !isLocalHost(window.location.hostname)
        ) {
            window.location.href = window.location.href.replace(/^http(?!s)/, 'https');

            return null;
        }

        return this.props.children;
    }
}

export default HttpsRedirect;
