import { createStyles, Grid, WithStyles } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import React from 'react';
import appStoreBadge from '../assets/app-store-badge.svg';
import googlePlayBadge from '../assets/google-play-badge.svg';

// #region UI
const strings = {
    text: {
        appStoreDownload: 'Pobierz w App Store',
        googlePlayDownload: 'Pobierz z Google Play',
    },
    url: {
        appStore: 'https://itunes.apple.com/pl/app/wiesz-co-jesz-lite/id897492825?l=pl&mt=8',
        googlePlay: 'https://play.google.com/store/apps/details?id=info.eatwhat.wcj&hl=pl',
    },
};

const styles = () =>
    createStyles({
        badge: {
            height: 64,
            width: 'auto',
        },
        date: {
            color: '#A1A5B1',
            fontSize: '15pt',
        },
        photo: {
            height: 400,
            objectFit: 'cover',
            width: '100%',
        },
        title: {
            color: '#171D33',
            fontSize: '22pt',
            fontWeight: 600,
        },
    });
// #endregion

class GetOnStores extends React.Component<WithStyles<typeof styles>> {
    // #region Lifecycle
    public render(): React.ReactNode {
        const { classes } = this.props;

        return (
            <Grid container={true} justify="center" spacing={2}>
                <Grid item={true}>
                    <a href={strings.url.appStore} target="_blank" rel="noopener noreferrer">
                        <img className={classes.badge} alt={strings.text.appStoreDownload} src={appStoreBadge} />
                    </a>
                </Grid>

                <Grid item={true}>
                    <a href={strings.url.googlePlay} target="_blank" rel="noopener noreferrer">
                        <img className={classes.badge} alt={strings.text.googlePlayDownload} src={googlePlayBadge} />
                    </a>
                </Grid>
            </Grid>
        );
    }
    // #endregion
}

export default withStyles(styles)(GetOnStores);
