import { Grid, Typography } from '@material-ui/core';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import React from 'react';
import logo from '../../assets/logo.png';
import GetOnStores from '../../components/GetOnStores';

// #region UI
const strings = {
    error: {
        notFound: '404 😱 Wybrana strona nie istnieje',
    },
    text: {
        appName: 'Wiesz Co Jesz',
    },
};

const styles = createStyles({
    container: {
        padding: 48,
    },
    logo: {
        height: 200,
        width: 200,
    },
    title: {
        fontSize: 64,
    },
});
// #endregion

class NotFoundPage extends React.Component<WithStyles<typeof styles>> {
    // #region Lifecycle
    public render(): React.ReactNode {
        const { classes } = this.props;

        return (
            <Grid
                container={true}
                alignItems="center"
                justify="center"
                direction="column"
                className={classes.container}
            >
                <Grid item={true} xs={12} className={classes.container}>
                    <img className={classes.logo} src={logo} alt={strings.text.appName} />
                </Grid>
                <Grid item={true}>
                    <Typography className={classes.title}>{strings.error.notFound}</Typography>
                </Grid>
                <Grid item={true}>
                    <GetOnStores />
                </Grid>
            </Grid>
        );
    }
    // #endregion
}

export default withStyles(styles)(NotFoundPage);
