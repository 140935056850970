import React from 'react';
import ReactDOM from 'react-dom';
import HttpsRedirect from './components/HttpsRedirect';
import App from './modules/app/App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
    <HttpsRedirect>
        <App />
    </HttpsRedirect>,
    document.getElementById('root') as HTMLElement
);

serviceWorker.unregister();
