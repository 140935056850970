import ArticlePage from '../modules/article/ArticlePage';
import NotFoundPage from '../modules/errors/NotFoundPage';
import HomePage from '../modules/home/HomePage';
import ProductPage from '../modules/product/ProductPage';

export const routesDetails = {
    error: {
        notFound: {
            component: NotFoundPage,
            path: '/blad/404',
        },
    },
    pages: {
        article: {
            component: ArticlePage,
            path: '/artykul/:articleId(\\d+)/:slug(.+).html',
            to: (articleId: number, slug: string) => `/artykul/${articleId}/${slug}.html`,
        },
        homePage: {
            component: HomePage,
            path: '/',
        },
        product: {
            component: ProductPage,
            path: '/produkt/:productId(\\d+)/:gtin(.+).html',
            to: (productId: number, gtin: string) => `/produkt/${productId}/${gtin}.html`,
        },
    },
};
